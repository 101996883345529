import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const KfcActivityIndicator = ({ isInProgress }) => {
  const maxLoadingTime = 25000;
  const intervalTimer = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const isApiLoading = state => {
    if (process?.env?.NODE_ENV === 'development') {
      Object.values(state).forEach(v => {
        const vKeys = Object.keys(v).filter(name => /Loading$/.test(name));
        vKeys.forEach(key => {
          if (v[key] === true) {
            console.log(`${key}: `, v[key]);
          }
        });
      });
    }
    return (
      state.appConfigsReducer?.isLoading || // App Configuration
      state.menuReducer?.isLoading || // Menu Reducer
      state.menuReducer?.isItemDetailsLoading ||
      // Content Reducer
      // state.contentReducer?.isContentfulLoading ||
      // Profile Reducer
      state.profileReducer?.isLoading ||
      // placesAutocompleteReducer
      state.placesAutocompleteReducer?.isLoading ||
      state.placesAutocompleteReducer?.isPlacesReverseGeoLoading ||
      state.placesAutocompleteReducer?.isPlacesGeoLoading ||
      // Store Reducer
      state.storesReducer?.isGoogleDistanceLoading ||
      state.storesReducer?.isStoreStatusLoading ||
      state.storesReducer?.isNearByStoresLoading ||
      state.storesReducer?.isStoreTimeSlotsLoading ||
      state.storesReducer?.isStoreDetailsLoading ||
      state.storesReducer?.isFavoriteStoreStatusLoading ||
      // Cart Reducer
      state.cartReducer?.isCouponLoading ||
      //state.cartReducer?.getCouponLoading ||
      state.cartReducer?.isAddItemLoading ||
      state.cartReducer?.isRemoveItemLoading ||
      state.cartReducer?.isRemoveAllItemLoading ||
      state.cartReducer?.isUpdateQuantityLoading ||
      state.cartReducer?.isBasketDetailsLoading ||
      state.cartReducer?.isBasketLoading ||
      state.cartReducer?.isValidateBasketLoading ||
      // gift card reducer
      state.giftCardPurchaseReducer?.isPurchaseLoading ||
      state.createBalanceReducer?.isGiftCardCheckLoading ||
      //add Hope reducer
      state.addHopeContentReducer?.isGetAddHopeContentLoading ||
      //more reducer
      state.moreReducer?.isGetMoreContentLoading ||
      // Payment
      state.paymentsReducer?.isGetPaymentTendersLoading ||
      state.paymentsReducer?.isNetbankPaymentLoading ||
      state.paymentsReducer?.isGetNetBankLoading ||
      state.paymentsReducer?.isPaytmPaymentLoading ||
      state.paymentsReducer?.isPhonePeStatusLoading ||
      state.paymentsReducer?.isPhonePayLoading ||
      state.paymentsReducer?.isPaytmStatusLoading ||
      state.paymentsReducer?.isWalletsListLoading ||
      // state.i18nReducer?.isI18Nloading ||
      state.privacyPolicyReducer?.isLoading ||
      state.termsOfUseReducer?.isLoading ||
      //Ads Reducer
      state.ourAdsReducer?.isLoading ||
      //Favourite menu reducer
      state.orderHistoryReducer?.orderHistoryLoading ||
      state.orderHistoryReducer?.addFavouriteOrderLoading ||
      state.orderHistoryReducer?.deleteFavouriteOrderLoading ||
      //google pay spot reducer
      state.paymentsGoogleSpotReducer?.isSpotLoading ||
      //order reducer
      state.orderReducer?.isPlaceOrder ||
      //Loader Action
      state.LoaderReducer?.isLoading ||
      //microAppPaymentReducer
      state.microAppPaymentReducer?.isPaytmPaymentLoading ||
      state.microAppPaymentReducer?.isPaytmPayStatusLoading ||
      state.microAppPaymentReducer?.isPhonepePaymentLoading ||
      state.microAppPaymentReducer?.isPhonepePayStatusLoading ||
      state.reorderReducer?.isReorderStoreStatusLoading ||
      state.authReducer?.isLoading ||
      // Brintree Payment
      state.braintreePaymentReducer?.isGenerateClientTokenLoading ||
      state.braintreePaymentReducer?.transactionSaleLoading ||
      state.braintreePaymentReducer?.transactionSubmitLoading ||
      state.braintreePaymentReducer?.transactionVoidLoading ||
      // Post Order Reducer
      state.postOrderReducer?.orderAcceptedLoading ||
      state.postOrderReducer?.cancelOrderLoading ||
      state.postOrderReducer?.checkInRequestLoading ||
      state.orderReducer?.isCreateOrderLoading ||
      state.brazeSubscriptionReducer?.loading
    );
  };

  const isLoading = useSelector(state => isApiLoading(state) ?? isInProgress);

  useEffect(() => {
    setIsVisible(isLoading);
    if (isLoading) {
      intervalTimer.current = setInterval(() => {
        setIsVisible(false);
      }, maxLoadingTime);
    } else {
      clearTimeout(intervalTimer.current);
    }
  }, [isLoading]);

  if (isVisible) {
    return (
      <>
        <div className='kfc-loader' data-testid='kfc-loader-id'>
          {/* <ImageComponent className='' srcFile={`${KFC_Loader_Gif}`} alt={'Loader'} /> */}
        </div>
      </>
    );
  }
  return null;
};

KfcActivityIndicator.propTypes = {
  isInProgress: PropTypes.bool,
};

KfcActivityIndicator.defaultProps = {
  isInProgress: false,
};

export default KfcActivityIndicator;
