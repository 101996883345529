import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  dispatchCreateBasketsAction,
  redirectToDeleteAccountPage,
  redirectToHomePage,
  setVirtualPageAnalytics,
  userParameterObject,
} from 'common/utilities/utils';
import AuthManager from '@kfc-global/react-shared/auth/AuthManager';
import { LOGIN_PATH_NAME } from '@kfc-global/react-shared/config/config.constants';
import { config } from '@kfc-global/react-shared/config/config.utils';
import AlertFormWrapper from 'molecules/Alert';
import AlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import {
  login,
  setAnalyticsRegistrationFinish,
  setAnalyticsSignInFinish,
  setAnalyticsSignInSkipped,
} from 'common/utilities/LoginUtils';
import { getGoogleTokens } from '@kfc-global/react-shared/redux/Actions/AuthAction';
import ConfirmedImage from 'styles/staticAssets/images/confirmed_1.svg';
import { setUserParams } from '@kfc-global/react-shared/redux/Actions/AppStateAction';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { AUTH_CONSTANTS, LOCAL_STORAGE_KEYS, ROUTE_URL, SESSION_STORAGE_KEYS } from 'common/constants/SharedConstants';
import { ChannelManager } from 'common/manager';
import { createBasketsAction } from '@kfc-global/react-shared/redux/Actions/CartAction';
import { UserOrderContext } from 'context/context';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import { useCanonicalUrl } from '../customHooks';

const useCustomHook = () => {
  const DispositionData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.DISPOSITION_NEW));
  const CollectionPoint = 'NA';
  const DispositionType = DispositionData && DispositionData.type ? DispositionData.type : 'NA';
  const Platform = 'web';
  const { USER_ID } = SESSION_STORAGE_KEYS;
  const StoreID =
    DispositionData && DispositionData.store && DispositionData.store.id ? DispositionData.store.id : 'NA';
  const UserId = typeof sessionStorage.getItem(USER_ID) !== 'undefined' ? sessionStorage.getItem(USER_ID) : 'NA';

  const appStateReducer = useSelector(data => data.appStateReducer) ?? {};
  const { loggedIn, localized } = appStateReducer?.userStatus ?? {};

  return {
    CollectionPoint,
    DispositionType,
    loggedIn,
    localized,
    Platform,
    StoreID,
    UserId,
  };
};

const LoginAnalyticsHandler = props => {
  const useCustomHookData = useCustomHook();
  const useLocationHook = useLocation();
  const { canonicalUrl } = useCanonicalUrl();
  const { SET_ATTRIBUTE_PAYLOAD, FORCED_SIGN_OUT } = SESSION_STORAGE_KEYS;

  useEffect(() => {
    let pageTitle = useLocationHook.pathname.substring(1) ? useLocationHook.pathname.substring(1) : 'NA';
    setTimeout(() => {
      sessionStorage.setItem(SET_ATTRIBUTE_PAYLOAD, JSON.stringify(useCustomHookData));
      userParameterObject(useCustomHookData);
      setVirtualPageAnalytics({
        triggeredEvent: 'virtualPageView',
        path: `${window.location.origin}${useLocationHook.pathname}`,
        pageHeading: pageTitle,
      });
    }, 120);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLocationHook]);

  const {
    customerDetailsError,
    updateCustomerFailed,
    userSessionExpired,
    isUserLoggedOut,
    accountDeleted,
    qrCodeScanTime,
    isNewCustomer,
    signInFailed,
    customerId,
    loggedIn,
    tenantId,
  } = useSelector(
    ({
      profileReducer: { accountDeleted = false, customerDetailsError = false, isNewCustomer = false, customerId } = {},
      authReducer: { userSessionExpired = false, isUserLoggedOut = false, signInFailed = false } = {},
      cartReducer: { updateCustomerFailed = false, qrCodeScanTime = '' } = {},
      tenantReducer: { tenantId = '' } = {},
      appStateReducer: { userStatus: { loggedIn = false } = {} } = {},
    }) => ({
      customerDetailsError,
      updateCustomerFailed,
      userSessionExpired,
      isUserLoggedOut,
      accountDeleted,
      qrCodeScanTime,
      isNewCustomer,
      signInFailed,
      customerId,
      loggedIn,
      tenantId,
    }),
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [fireLoginAnalytics, setFireLoginAnalytics] = useState(false);
  const [showCartErrorPopup, setShowCartErrorPopup] = useState(false);
  const [showSessionExpiredPopup, setShowSessionExpiredPopup] = useState(false);
  const { userOrderState } = useContext(UserOrderContext);

  const { CONTINUE_AS_GUEST, SIGN_IN, SKIPPED, TOKEN_EXPIRED, CONTINUE, OOPS_WENT_WRONG, RETAIN_CART_MESSAGE } =
    AUTH_CONSTANTS;
  const { ACCOUNT, HOME_PAGE, SIGNOUT } = ROUTE_URL;

  const { getInstance: auth } = AuthManager;

  useEffect(() => {
    if (loggedIn && fireLoginAnalytics && customerId) {
      setAnalyticsRegistrationFinish(isNewCustomer);
      setAnalyticsSignInFinish();
      setFireLoginAnalytics(false);
    }
  }, [loggedIn, isNewCustomer, fireLoginAnalytics, customerId]);

  const navigateToHome = useCallback(() => {
    if (history?.location?.pathname?.includes(ACCOUNT)) {
      history?.push({
        pathname: HOME_PAGE,
      });
    }
  }, [ACCOUNT, HOME_PAGE, history]);

  useEffect(() => {
    if (history.location.pathname?.includes(config(LOGIN_PATH_NAME))) {
      (async () => {
        try {
          await auth().processLogin(window.location.href);
        } catch (e) {
          if (e?.loginResult === SKIPPED) {
            setAnalyticsSignInSkipped();
          } else {
            auth().bootstrapAuthentication();
          }
        }
      })();
      const REGEX = new RegExp(/account|order-details/);
      const navUrl = localStorage.getItem('navUrl') ?? ROUTE_URL.HOME_PAGE;
      if (REGEX.test(navUrl)) {
        redirectToHomePage({ history });
      } else {
        history.push({
          pathname: navUrl,
        });
        localStorage.removeItem('navUrl');
      }
      setFireLoginAnalytics(true);
    }
  }, [SKIPPED, auth, history]);

  useEffect(() => {
    const navUrl = localStorage.getItem('navUrl');
    if (loggedIn && navUrl) {
      localStorage.removeItem('navUrl');
      !history.location.pathname?.includes(navUrl) &&
        history.push({
          pathname: navUrl,
        });
    } else if (navUrl) {
      const pathName = history?.location?.pathname;
      // This if condition has been implemented for defect 15747 and 15733
      if (
        pathName?.includes(ROUTE_URL.PRIVACY_POLICY_LINK) ||
        pathName?.includes(ROUTE_URL.PRIVACY_POLICY) ||
        pathName?.includes(ROUTE_URL.TERMS_AND_CONDITIONS) ||
        pathName?.includes(ROUTE_URL.TERMS_OF_USE) ||
        pathName?.includes(ROUTE_URL.TERMS_CONDITIONS)
      ) {
        return;
      }

      history.push({
        pathname: navUrl,
      });
      localStorage.removeItem(LOCAL_STORAGE_KEYS.NAV_URL);
    }
  }, [loggedIn, history]);

  const continueAsGuest = useCallback(() => {
    navigateToHome();
    setShowSessionExpiredPopup(false);
    auth().bootstrapAuthentication();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch, navigateToHome]);

  const signIn = useCallback(() => {
    navigateToHome();
    setShowSessionExpiredPopup(false);
    login();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigateToHome]);

  useEffect(() => {
    if (loggedIn && userSessionExpired) {
      (async () => {
        await ChannelManager.BindPhone();
        ChannelManager.googlePhoneToken && dispatch(getGoogleTokens({ token: ChannelManager.googlePhoneToken }));
      })();
      navigateToHome();
      setShowSessionExpiredPopup(!ChannelManager.isGoogle);
      dispatch(setUserParams({ loggedIn: false }));
    }
  }, [dispatch, loggedIn, navigateToHome, userSessionExpired]);

  const createNewBasket = useCallback(() => {
    dispatchCreateBasketsAction(dispatch, createBasketsAction, tenantId, userOrderState, qrCodeScanTime);
    setShowCartErrorPopup(false);
  }, [dispatch, qrCodeScanTime, tenantId, userOrderState]);

  useEffect(() => {
    setShowCartErrorPopup(updateCustomerFailed);
  }, [updateCustomerFailed]);

  useEffect(() => {
    if (isUserLoggedOut && !accountDeleted && !history.location.pathname?.includes(SIGNOUT)) {
      history.push({
        pathname: SIGNOUT,
      });
    }
  }, [history, isUserLoggedOut, accountDeleted, customerDetailsError, SIGNOUT]);

  useEffect(() => {
    if (signInFailed && !ChannelManager.isGoogle) {
      sessionStorage.setItem(FORCED_SIGN_OUT, true);
      history.push({
        pathname: SIGNOUT,
      });
    }
  }, [history, signInFailed, dispatch, SIGNOUT, auth, FORCED_SIGN_OUT]);

  useEffect(() => {
    if (accountDeleted) {
      dispatch(setUserParams({ loggedIn: false }));
      redirectToDeleteAccountPage({ history });
    }
  }, [SIGNOUT, accountDeleted, dispatch, history]);

  const cartErrorPopup = (
    <AlertForm
      imageSrc={ConfirmedImage}
      imageClassName='alert-confirm-image'
      splitTitle={{
        MSGTXT2: OOPS_WENT_WRONG,
        MSGTXT3: RETAIN_CART_MESSAGE,
      }}
      confirmButtonText={translateWithI18Next(CONTINUE)}
      confirmHandler={createNewBasket}
    />
  );

  const sessionExpiryPopup = (
    <AlertForm
      imageSrc={ConfirmedImage}
      imageClassName='alert-confirm-image'
      title={translateWithI18Next(TOKEN_EXPIRED)}
      confirmButtonText={translateWithI18Next(CONTINUE_AS_GUEST)}
      confirmHandler={continueAsGuest}
      cancelButtonText={translateWithI18Next(SIGN_IN)}
      cancelHandler={signIn}
    />
  );

  return (
    <>
      {!isEmpty(canonicalUrl) && (
        <Helmet>
          <link rel='canonical' href={canonicalUrl} />
        </Helmet>
      )}
      {props.children}
      {showCartErrorPopup && <AlertFormWrapper classNames='cart-update-error-modal' CartAlertForm={cartErrorPopup} />}
      {showSessionExpiredPopup && (
        <AlertFormWrapper classNames='user-session-expiry-modal' CartAlertForm={sessionExpiryPopup} />
      )}
    </>
  );
};

export default LoginAnalyticsHandler;
